import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomUrlSerializer implements UrlSerializer {
  defaultUrlSerializer: DefaultUrlSerializer;

  constructor() {
    this.defaultUrlSerializer = new DefaultUrlSerializer();
  }

  parse(url: string): UrlTree {
    const parsed = this.defaultUrlSerializer.parse(url);
    return parsed;
  }

  serialize(tree: UrlTree): string {
    const decoded = decodeURIComponent(this.defaultUrlSerializer.serialize(tree));
    return decoded;
  }
}
