import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigProperty, ConfigService } from '@sds/shared';
import { Observable } from 'rxjs';

@Injectable()
export class NgswBypassInterceptor implements HttpInterceptor {
  constructor(private readonly configService: ConfigService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const bypassUrls = [`${this.configService.get(ConfigProperty.AdministrationApi)}/mercure/token`];

    if (bypassUrls.includes(req.url)) {
      const newReq = req.clone({
        setHeaders: {
          'ngsw-bypass': 'true'
        }
      });

      return next.handle(newReq);
    }
    return next.handle(req);
  }
}
