import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable()
export class Oauth2Interceptor implements HttpInterceptor {
  constructor(private readonly oauthService: OAuthService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.oauthService.hasValidAccessToken() && !req.url.includes('openid')) {
      const token = 'Bearer ' + this.oauthService.getAccessToken();
      const newReq = req.clone({
        setHeaders: {
          Authorization: token
        }
      });

      return next.handle(newReq);
    }
    return next.handle(req);
  }
}
