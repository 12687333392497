import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Renderer2, effect, inject } from '@angular/core';
import {
  Color,
  PortalColors,
  PortalSettingsStore,
  SharedModule,
  SystemMediaService,
  Theme,
  computeColors
} from '@sds/shared';
import { UserSettingsStore } from '@sds/user-settings';
import { RouteHandlerComponent } from '../route-handler/route-handler.component';

@Component({
  standalone: true,
  imports: [SharedModule, RouteHandlerComponent],
  selector: 'sds-theme-handler',
  templateUrl: './theme-handler.component.html',
  styleUrls: ['./theme-handler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeHandlerComponent {
  private userSettingsStore = inject(UserSettingsStore);
  private portalSettingsStore = inject(PortalSettingsStore);

  constructor(
    private readonly renderer: Renderer2,
    private readonly systemMediaService: SystemMediaService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    effect(() => {
      const themes: string[] = Object.values(Theme);
      themes.forEach(item => {
        this.renderer.removeClass(this.document.body, item);
      });

      if (this.userSettingsStore.theme() !== Theme.System) {
        this.renderer.addClass(
          this.document.body,
          this.userSettingsStore.theme() ? this.userSettingsStore.theme() : Theme.Dark
        );
      } else {
        this.renderer.addClass(
          this.document.body,
          this.systemMediaService.prefersColorScheme() === 'dark' ? Theme.Dark : Theme.Atomic
        );
      }
    });

    effect(() => {
      this.applyCssVars(this.userSettingsStore.theme(), this.portalSettingsStore.themeColors());
    });

    effect(() => {
      if (this.userSettingsStore.theme() === Theme.System) {
        this.applyCssVars(
          this.systemMediaService.prefersColorScheme() === 'dark' ? Theme.Dark : Theme.Atomic,
          this.portalSettingsStore.themeColors()
        );
      }
    });
  }

  private applyCssVars(theme: Theme, themeColors: PortalColors) {
    switch (theme) {
      case Theme.Light:
        this.applyPalette(themeColors.lightPrimaryColor, 'primary');
        this.applyPalette(themeColors.lightAccentColor, 'accent');
        this.applyPalette('#dc2626', 'warn');
        this.applyPalette('#16a34a', 'success');
        this.applyPalette('#f59e0b', 'alert');
        break;
      case Theme.Atomic:
        this.applyPalette('#0e60bf', 'primary');
        this.applyPalette('#00a3bf', 'accent');
        this.applyPalette('#dc2626', 'warn');
        this.applyPalette('#16a34a', 'success');
        this.applyPalette('#f59e0b', 'alert');
        break;
      default:
        this.applyPalette(themeColors.darkPrimaryColor, 'primary');
        this.applyPalette(themeColors.darkAccentColor, 'accent');
        this.applyPalette('#F44336', 'warn');
        this.applyPalette('#16a34a', 'success');
        this.applyPalette('#f59e0b', 'alert');
        break;
    }
  }

  private applyPalette(color: string, palette: 'primary' | 'accent' | 'warn' | 'success' | 'alert'): void {
    const colors = computeColors(color);
    this.updateTheme(colors, palette);
  }

  private updateTheme(colors: Color[], palette: 'primary' | 'accent' | 'warn' | 'success' | 'alert') {
    colors.forEach(color => {
      this.document.documentElement.style.setProperty(`--palette-${palette}-${color.name}`, color.hex);
      this.document.documentElement.style.setProperty(
        `--palette-${palette}-contrast-${color.name}`,
        color.darkContrast ? 'rgba(0, 0, 0, 0.87)' : 'white'
      );
    });
  }
}
