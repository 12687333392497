import { Injector } from '@angular/core';
import { type ErrorEntity, ErrorType } from '@sds/api';
import { ErrorResolver } from '@sds/shared';
import { BaseWidget } from '@sds/widget-gridster';
import { filter, first } from 'rxjs/operators';

export class ComponentHistoryWidget extends BaseWidget<{ error: ErrorEntity; fleetId: string }> {
  static id = 'component-history';
  static readonly publicFriendly = false;
  private readonly resolver: ErrorResolver = this.injector.get(ErrorResolver);

  static factory(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { errorId: string; fleetId: string; errorType: ErrorType }
  ) {
    return new ComponentHistoryWidget(injector, metaLinkId, x, y, rows, cols, data);
  }

  constructor(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { errorId: string; fleetId: string; errorType: ErrorType }
  ) {
    super(
      injector,
      ComponentHistoryWidget.id,
      ComponentHistoryWidget.publicFriendly,
      metaLinkId,
      x,
      y,
      rows,
      cols,
      data
    );
    this.resolveComponent(async () => {
      const module = await import('./monitoring-plant-error-component-history.component');
      return Object.values(module)[0];
    });
  }

  override resolveData(data: { errorId: string; errorType: ErrorType; fleetId: string }): void {
    this.resolver
      .resolve(data.errorType, data.errorId)
      .pipe(
        first(),
        filter(error => error !== null)
      )
      .subscribe((error: ErrorEntity) => this.setData({ error, fleetId: data.fleetId }));
  }
}
