import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigProperty, ConfigService } from '@sds/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MigrationTokenReloadInterceptor implements HttpInterceptor {
  constructor(private readonly configService: ConfigService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 &&
          this.configService.get(ConfigProperty.TokenEndpoint) ===
            'https://app-api-token-r-euwe-19e26c.azurewebsites.net'
        ) {
          location.reload();
        }
        return next.handle(request);
      })
    );
  }
}
