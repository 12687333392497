import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigProperty, ConfigService } from '@sds/shared';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, from, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly configService: ConfigService,
    private readonly oauthService: OAuthService
  ) {}

  intercept(req: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let retried = false;
    if (req.url.startsWith(this.configService.get(ConfigProperty.BaseApiUrl))) {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          // if 401 error, refresh the token and repeat the request
          if (!retried && error.status === 401) {
            retried = false;
            return from(this.oauthService.refreshToken()).pipe(switchMap(() => next.handle(req.clone())));
          }
          return throwError(() => error);
        })
      );
    }

    return next.handle(req);
  }
}
