import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TitleFactory extends TitleStrategy {
  constructor(
    private readonly translateService: TranslateService,
    private readonly titleService: Title
  ) {
    super();
  }
  updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    this.titleService.setTitle(this.translateService.instant(`pageTitles.${title ? title : 'default'}`));
  }
}
