import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  Router,
  TitleStrategy,
  UrlSerializer,
  provideRouter,
  withComponentInputBinding,
  withViewTransitions
} from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  ConfigService,
  DateFormatterPipe,
  DateTimePipe,
  DayFormatterPipe,
  DeviceStore,
  DistanceFromNowFormatterPipe,
  DurationFromSecondsPipe,
  Environment,
  ErrorDescriptionPipe,
  ExtendedDateFormatterPipe,
  HoursFormatterPipe,
  JediVsSithPipe,
  NumberFormatterPipe,
  ReplaceTranslationKey,
  SharedModule,
  ShortTimeFormatterPipe,
  TimeAgoPipe,
  TimeFormatterPipe,
  TypeofPipe,
  UnitFormatterPipe
} from '@sds/shared';
import { TraceService, createErrorHandler } from '@sentry/angular';
import { OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc';
import { provideLottieOptions } from 'ngx-lottie';
import { environment } from '../environments/environment';
import { deviceMediaObserverInitializer } from './initializers/device-media-observer.initializer';
import { InitializerService, initializerFactory } from './initializers/initializer.service';
import { WidgetFactoryInitializer, widgetFactory } from './initializers/widget-factory.initializer';
import { httpInterceptorProviders } from './interceptors';
import { storageFactory } from './oauth2.config';
import { TitleFactory } from './page-title.factory';
import { routes } from './routes';
import { translateLoaderFactory } from './translate-loader.factory';
import { CustomUrlSerializer } from './url-serializer';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(routes, withViewTransitions(), withComponentInputBinding()),
    provideOAuthClient(),
    provideLottieOptions({ player: playerFactory }),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      SharedModule.forRoot({
        environment: environment.production ? Environment.PROD : Environment.DEV
      }),
      TranslateModule.forRoot({
        loader: { provide: TranslateLoader, useFactory: translateLoaderFactory, deps: [HttpClient, ConfigService] }
      }),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        registrationStrategy: 'registerWhenStable:10000'
      }),
      LoadingBarHttpClientModule,
      LoadingBarModule,
      LoadingBarRouterModule
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      deps: [InitializerService, ConfigService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: deviceMediaObserverInitializer,
      deps: [DeviceStore, BreakpointObserver],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: widgetFactory,
      deps: [WidgetFactoryInitializer],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true
    },
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: TraceService,
      deps: [Router]
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: TitleStrategy, useClass: TitleFactory },
    JediVsSithPipe,
    TimeAgoPipe,
    DayFormatterPipe,
    DateFormatterPipe,
    DateTimePipe,
    DurationFromSecondsPipe,
    ExtendedDateFormatterPipe,
    HoursFormatterPipe,
    ShortTimeFormatterPipe,
    NumberFormatterPipe,
    TimeFormatterPipe,
    DistanceFromNowFormatterPipe,
    UnitFormatterPipe,
    ReplaceTranslationKey,
    ErrorDescriptionPipe,
    TypeofPipe
  ]
};
